Ember.TEMPLATES["javascripts/discourse/connectors/composer-fields-below/collusion-buttons"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\",\"creatingCollusion\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"leave-collusion\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"site\",\"mobileView\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"tabindex\",\"6\"],[12,\"title\",[29,[[28,\"i18n\",[\"collude.done\"],null]]]],[3,\"action\",[[23,0,[]],\"endCollusion\"]],[8],[1,[28,\"d-icon\",[\"times\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"btn btn-primary\"],[12,\"tabindex\",\"6\"],[3,\"action\",[[23,0,[]],\"endCollusion\"]],[8],[1,[28,\"i18n\",[\"collude.done\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/connectors/composer-fields-below/collusion-buttons"}});
define("discourse/plugins/collude/discourse/connectors/composer-fields-below/collusion-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    actions: {
      endCollusion: function endCollusion() {
        this.appEvents.trigger('composer:close');
      }
    }
  };
  _exports.default = _default;
});
define("discourse/plugins/collude/discourse/initializers/collude-init", ["exports", "discourse/lib/plugin-api", "ember-addons/ember-computed-decorators", "../lib/collude", "discourse/models/composer"], function (_exports, _pluginApi, _emberComputedDecorators, _collude, _composer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var COLLUDE_ACTION = 'colludeOnTopic';
  var _default = {
    name: 'collude-button',
    initialize: function initialize() {
      (0, _pluginApi.withPluginApi)('0.8.6', function (api) {
        var _dec, _dec2, _obj;

        _composer.default.serializeOnCreate('collude');

        var siteSettings = api.container.lookup('site-settings:main');

        if (!siteSettings.collude_enabled) {
          return;
        }

        api.includePostAttributes('collude');
        api.addPostMenuButton('collude', function (post) {
          if (!post.collude || !post.canEdit) {
            return;
          }

          return {
            action: COLLUDE_ACTION,
            icon: 'handshake-o',
            label: 'collude.collaborate',
            title: 'collude.button_title',
            className: 'collude create',
            position: 'last'
          };
        });
        api.reopenWidget('post-menu', {
          menuItems: function menuItems() {
            var result = this._super();

            if (this.attrs.collude) {
              this.attrs.wiki = false;

              if (_.contains(result, 'edit')) {
                result.splice(result.indexOf('edit'), 1);
              }
            }

            return result;
          }
        });
        api.reopenWidget('post-admin-menu', {
          html: function html(attrs, state) {
            var contents = this._super(attrs, state);

            if (!this.currentUser.staff || attrs.post_number != 1) {
              return contents;
            }

            contents.push(this.attach('post-admin-menu-button', {
              action: 'toggleCollusion',
              icon: 'handshake-o',
              className: 'admin-collude',
              label: attrs.collude ? 'collude.disable_collusion' : 'collude.enable_collusion'
            }));
            return contents;
          },
          toggleCollusion: function toggleCollusion() {
            var _this = this;

            (0, _collude.toggleCollusion)(this.attrs.id).then(function () {
              _this.scheduleRerender();
            });
          }
        });
        api.modifyClass('component:scrolling-post-stream', {
          colludeOnTopic: function colludeOnTopic() {
            this.appEvents.trigger('collude-on-topic');
          }
        });
        api.modifyClass('model:composer', {
          creatingCollusion: Em.computed.equal("action", COLLUDE_ACTION)
        });
        api.modifyClass('controller:topic', {
          init: function init() {
            var _this2 = this;

            this._super();

            this.appEvents.on('collude-on-topic', function () {
              _this2.get('composer').open({
                topic: _this2.model,
                action: COLLUDE_ACTION,
                draftKey: _this2.model.draft_key
              });
            });
          },
          willDestroy: function willDestroy() {
            this._super();

            this.appEvents.off('collude-on-topic');
          }
        });
        api.modifyClass('controller:composer', (_dec = (0, _emberComputedDecorators.on)('init'), _dec2 = (0, _emberComputedDecorators.observes)('model.reply'), (_obj = {
          open: function open(opts) {
            var _this3 = this;

            this._super(opts).then(function () {
              if (opts.action == COLLUDE_ACTION) {
                (0, _collude.setupCollusion)(_this3.model);
              }
            });
          },
          collapse: function collapse() {
            if (this.get('model.action') == COLLUDE_ACTION) {
              return this.close();
            }

            return this._super();
          },
          close: function close() {
            if (this.get('model.action') == COLLUDE_ACTION) {
              (0, _collude.teardownCollusion)(this.model);
            }

            return this._super();
          },
          _listenForClose: function _listenForClose() {
            var _this4 = this;

            this.appEvents.on('composer:close', function () {
              _this4.close();
            });
          },
          _handleCollusion: function _handleCollusion() {
            if (this.get('model.action') == COLLUDE_ACTION) {
              (0, _collude.performCollusion)(this.model);
            }
          },
          _saveDraft: function _saveDraft() {
            if (this.get('model.action') == COLLUDE_ACTION) {
              return;
            }

            return this._super();
          }
        }, (_applyDecoratedDescriptor(_obj, "_listenForClose", [_dec], Object.getOwnPropertyDescriptor(_obj, "_listenForClose"), _obj), _applyDecoratedDescriptor(_obj, "_handleCollusion", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_handleCollusion"), _obj)), _obj)));
      });
    }
  };
  _exports.default = _default;
});
define("discourse/plugins/collude/discourse/lib/collude-dom", ["exports", "discourse/components/site-header"], function (_exports, _siteHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.expandCollusion = void 0;

  var expandCollusion = function expandCollusion() {
    Ember.run.scheduleOnce('afterRender', function () {
      var $colluder = $('#reply-control.open');
      $colluder.height($(window).height() - (0, _siteHeader.headerHeight)());
      $colluder.find('.save-or-cancel').remove();
      $colluder.find('.submit-panel > span').css('flex-basis', '50%');
    });
  };

  _exports.expandCollusion = expandCollusion;
});
define("discourse/plugins/collude/discourse/lib/collude", ["exports", "discourse/lib/ajax", "./collude-dom", "discourse/models/user"], function (_exports, _ajax, _colludeDom, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toggleCollusion = _exports.performCollusion = _exports.teardownCollusion = _exports.setupCollusion = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var messageBus = function messageBus() {
    return Discourse.__container__.lookup('message-bus:main');
  };

  var store = function store() {
    return Discourse.__container__.lookup("service:store");
  }; // connect to server and request initial document


  var setupCollusion = function setupCollusion(composer) {
    var resolve = function resolve(data) {
      if (_user.default.currentProp('id') == data.collusion.actor_id) {
        return;
      }

      composer.set('reply', data.collusion.value || "");
      composer.set('changesets', {
        performed: data.collusion.changeset,
        submitted: data.collusion.changeset,
        confirmed: data.collusion.changeset
      });
    };

    (0, _colludeDom.expandCollusion)();
    messageBus().subscribe("/collusions/".concat(composer.get('topic.id')), resolve);
    return (0, _ajax.ajax)("/collusions/".concat(composer.get('topic.id'))).then(resolve);
  }; // push local changes to the server


  _exports.setupCollusion = setupCollusion;

  var performCollusion = function performCollusion(composer) {
    if (!composer.changesets) {
      return;
    }

    composer.set('changesets.performed', resolveChangeset(composer.changesets.submitted, {
      length_before: 0,
      length_after: composer.reply.length,
      changes: [composer.reply]
    }));
    return putCollusion(composer);
  };

  _exports.performCollusion = performCollusion;

  var toggleCollusion = function toggleCollusion(postId) {
    return store().find('post', postId).then(function (post) {
      post.set('collude', !post.collude);
      return (0, _ajax.ajax)("/collusions/".concat(post.topic_id, "/toggle"), {
        type: 'POST'
      });
    });
  }; //// private


  _exports.toggleCollusion = toggleCollusion;

  var putCollusion = _.debounce(function (composer) {
    if (_.isEqual(composer.changesets.performed, composer.changesets.submitted)) {
      return;
    }

    composer.set('changesets.submitted', composer.changesets.performed);
    (0, _ajax.ajax)("/collusions", {
      type: 'POST',
      data: {
        id: composer.topic.id,
        changeset: composer.changesets.submitted
      }
    }).then(function (data) {
      composer.set('changesets.confirmed', data.collusion.changeset);
    });
  }, Discourse.SiteSettings.collude_debounce);

  var teardownCollusion = function teardownCollusion(composer) {
    messageBus().unsubscribe("/collusions/".concat(composer.get('topic.id')));
  };

  _exports.teardownCollusion = teardownCollusion;

  var resolveChangeset = function resolveChangeset(prev, next) {
    if (_.isEqual(prev, next)) {
      return prev;
    }

    return {
      length_before: prev.length_after,
      length_after: next.length_after,
      changes: resolveChanges(prev, next)
    };
  };

  var resolveChanges = function resolveChanges(prev, next) {
    var _prev = fullChangesArray(prev);

    var _next = fullChangesArray(next);

    return compressChanges(_.range(_next.length).map(function (index) {
      if (_next[index] == _prev[index]) {
        return index;
      }

      return typeof _next[index] == 'string' && _next[index] || typeof _prev[index] == 'string' && _prev[index] || index;
    }));
  };

  var compressChanges = function compressChanges(expanded) {
    return _.reduce(expanded, function (array, change) {
      var prevMode = (_.last(array) || []).slice(0, 2) == 'øø' ? 'unchanged' : 'changed';
      var currMode = typeof change == 'number' ? 'unchanged' : 'changed';

      if (prevMode == currMode) {
        var curr = array.pop() || [];

        switch (prevMode) {
          case 'changed':
            array.push("".concat(curr).concat(change));
            break;

          case 'unchanged':
            array.push("".concat(curr.split('-')[0], "-").concat(change));
            break;
        }
      } else {
        switch (currMode) {
          case 'changed':
            array.push(change);
            break;

          case 'unchanged':
            array.push("\xF8\xF8".concat(change, "-").concat(change));
            break;
        }
      }

      return array;
    }, []);
  };

  var fullChangesArray = function fullChangesArray(changeset) {
    return _.reduce(changeset.changes, function (array, change) {
      if (change.slice(0, 2) == 'øø') {
        var _change$replace$split = change.replace('øø', '').split('-'),
            _change$replace$split2 = _slicedToArray(_change$replace$split, 2),
            b = _change$replace$split2[0],
            e = _change$replace$split2[1];

        return array.concat(_.range(parseInt(b), parseInt(e) + 1));
      } else {
        return array.concat(change.split(''));
      }
    }, []);
  };
});

